import get from 'lodash/get'
import set from 'lodash/set'
import { apiRoutes } from '~/modules/reporting/config'
import { getInstance } from '~/plugins/http'

export default {
  namespaced: true,

  actions: {
    async fetchBusinessStats (store) {
      store.commit('SET_BUSINESS_STATS', null)

      const response = await getInstance().get(apiRoutes.stats.business)

      store.commit('SET_BUSINESS_STATS', get(response, 'data.data', {}))

      return store.getters.business
    },
    async fetchLoanStats (store) {
      store.commit('SET_LOAN_STATS', null)

      const response = await getInstance().get(apiRoutes.stats.loans)

      store.commit('SET_LOAN_STATS', get(response, 'data.data', {}))

      return store.getters.loans
    },
    async fetchLoanUpdatesStats (store) {
      store.commit('SET_LOAN_UPDATES_STATS', null)

      const response = await getInstance().get(apiRoutes.stats.loanUpdates)

      store.commit('SET_LOAN_UPDATES_STATS', get(response, 'data.data', {}))

      return store.getters.loanUpdates
    },
    async fetchUsersStats (store) {
      store.commit('SET_USERS_STATS', null)

      const response = await getInstance().get(apiRoutes.stats.users)

      store.commit('SET_USERS_STATS', get(response, 'data.data', {}))

      return store.getters.users
    },
    async fetchOffsettingStats (store) {
      store.commit('SET_OFFSETTING_STATS', null)

      const response = await getInstance().get(apiRoutes.stats.offsetting)

      store.commit('SET_OFFSETTING_STATS', get(response, 'data.data', {}))

      return store.getters.offsetting
    },
  },
  getters: {
    business: state => state.business,
    loans: state => state.loans,
    loanUpdates: state => state.loanUpdates,
    users: state => state.users,
    offsetting: state => state.offsetting,
  },
  mutations: {
    SET_BUSINESS_STATS: (state, stats) => set(state, 'business', stats),
    SET_LOAN_STATS: (state, stats) => set(state, 'loans', stats),
    SET_LOAN_UPDATES_STATS: (state, stats) => set(state, 'loanUpdates', stats),
    SET_USERS_STATS: (state, stats) => set(state, 'users', stats),
    SET_OFFSETTING_STATS: (state, stats) => set(state, 'offsetting', stats),
  },
  state: {
    business: null,
    loans: null,
    loanUpdates: null,
    users: null,
    offsetting: null,
  },
}
