import get from 'lodash/get'
import { Transformer } from '~/contracts/entity'
import Team from '~/modules/teams/entities/frontend/team'
import Transaction from './transaction'

export default class DonationTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    let isMatchedValue = this.getMatchedValue(data)

    Object.defineProperties(this, {
      isReversible: {
        enumerable: true,
        value: get(data, 'donation.data.reversible', false),
      },
      isMatched: {
        enumerable: true,
        value: isMatchedValue,
      },
      type: {
        enumerable: true,
        value: 'donation',
      },
    })
  }

  // Figure out what the value of isMatched should be - if this constructor is
  // being called as the result of an attribution change, matchPotClaim.data.id
  // won't exist.
  getMatchedValue(data) {
    let isMatchedValue = true

    if (data.isMatched === true) {
      isMatchedValue = true
    }

    if (data.isMatched === false) {
      isMatchedValue = false
    }

    if (get(data, 'matchPotClaim.data.id') === null) {
      isMatchedValue = false
    }

    return isMatchedValue
  }

  _transformers () {
    const transformers = super._transformers()

    return Object.assign({}, transformers, {
      team: { default: null, type: Transformer.item(Team) },
    })
  }
}
