/**
 * @typedef {{}} Users~Config~ApiRoutes
 * @property {{}} admin
 * @property {{}} frontend
 * @property {{}} frontend.myAccount
 * @property {{}} frontend.update
 */
/**
 * @type {Users~Config~ApiRoutes}
 */
export const apiRoutes = {
  admin: {
    bulkCreate: {
      downloadTemplate: '/admin/users/bulk/add/template/download',
      upload: '/admin/users/bulk/add',
    },
    credit: {
      amend: '/admin/users/<%= userId %>/credit',
      config: '/admin/users/credit/options',
    },
    fetch: '/admin/users/<%= userId %>',
    emailChangeHistory: '/admin/users/<%= userId %>/email-change-history',
    giftAidHistory: '/admin/users/<%= userId %>/giftaid-history',
    index: '/admin/users',
    makeDormant: '/admin/users/<%= userId %>/make-dormant',
    markDeceased: '/admin/users/<%= userId %>/mark-deceased',
    mfis: {
      attach: '/admin/users/<%= userId %>/mfis/<%= mfiId %>/attach',
      detach: '/admin/users/<%= userId %>/mfis/<%= mfiId %>/detach',
    },
    notes: '/admin/users/<%= userId %>/notes',
    re: {
      bulkUpdate: {
        downloadTemplate: '/admin/users/bulk/re-update/template/download',
        upload: '/admin/users/bulk/re-update',
      },
    },
    reverseDonation: '/admin/users/<%= userId %>/donations/<%= donationId %>/reverse',
    roles: {
      assign: '/admin/users/<%= userId %>/roles/<%= role %>/assign',
      config: '/admin/users/roles/options',
      revoke: '/admin/users/<%= userId %>/roles/<%= role %>/remove',
    },
    update: {
      avatar: '/admin/users/<%= userId %>/avatar',
      details: '/admin/users/<%= userId %>/details',
      settings: '/admin/users/<%= userId %>/settings',
    },
    transactions: '/admin/users/<%= userId %>/transaction-history',
    transactionsDownload: '/admin/users/<%= userId %>/downloads/transaction-history/<%= year %>',
  },
  frontend: {
    closeAccount: '/users/make-dormant',
    get: '/users/<%= userUuid %>',
    microgrants: '/users/<%= userUuid %>/microgrants',
    microloans: '/users/<%= userUuid %>/microloans',
    myAccount: {
      attribute: '/my-account/teams/<%= teamUuid %>/attribute',
      bonusStats: '/my-account/users/bonus-stats',
      currentUser: '/users',
      microgrants: '/my-account/users/microgrants',
      microloans: '/my-account/users/microloans',
      overview: '/my-account/users/overview',
      teams: '/my-account/teams/my-teams',
      transactions: '/my-account/users/transaction-history',
      transactionsDownload: '/my-account/users/downloads/transaction-history/<%= year %>',
      unattribute: '/my-account/teams/unattribute',
      update: {
        avatar: '/my-account/users/avatar',
        details: '/my-account/users/details',
        profile: '/my-account/users/profile',
        settings: '/my-account/users/settings',
      },
      withdraw: '/my-account/withdrawals',
      withdrawalConfig: '/my-account/withdrawals/options',
    },
    register: '/users/registration',
  },
}

/**
 * Time for cache to expire (in seconds).
 * @type {number}
 */
export const cacheTTL = 300

/**
 * The prefix to images' public IDs.
 * @const {string}
 */
export const imagePublicIdPrefix = 'lwc/lenders'

/**
 * PublicID to use when user's avatar is null.
 * @const {string}
 */
export const defaultLenderAvatar = `${imagePublicIdPrefix}/avatar_single`

/**
 * Maximum length of the "About" field's value.
 * @type {number}
 */
export const maxAboutLength = 500

/**
 * Maximum length of the "Why you lend" field's value.
 * @type {number}
 */
export const maxWhyGiveLength = 500

/**
 * Cloudinary upload preset name.
 * @type {string}
 */
export const uploadPreset = 'user-avatar'

/**
 * @typedef {{}} Users~Permissions
 * @property ho {string}
 * @property mfi {string}
 */

/**
 * Permissions to check when rendering navigation and before performing actions.
 * @type {Users~Permissions}
 */
export const permissions = {
  ho: 'ho-users',
  mfi: 'mfi-users',
}

/**
 * @typedef {{}} Users~Config
 * @property {Users~Config~ApiRoutes} apiRoutes
 * @property {number} cacheTTL
 * @property {string} defaultLenderAvatar
 * @property {string} imagePublicIdPrefix
 * @property {number} maxAboutLength
 * @property {number} maxWhyGiveLength
 * @property {string} uploadPreset
 */
export default {
  apiRoutes,
  cacheTTL,
  defaultLenderAvatar,
  imagePublicIdPrefix,
  maxAboutLength,
  maxWhyGiveLength,
  uploadPreset,
}
