import get from 'lodash/get'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/users/config'
import { getInstance } from '~/plugins/http'

/**
 * Extract response data and map to user entity.
 * @param {Store} store
 * @param {AxiosResponse} response
 * @return {AdminUser|null}
 */
function userFromResponse (store, response) {
  const userData = get(response, 'data.data', null)

  store.commit('SET_USER', userData)

  return store.getters.user
}

export default {
  /**
   * Amend user credit by creating a custom top-up.
   * @param {Store} store
   * @param {{}} data - Model data
   * @param {number} userId
   * @return {Promise<AdminUser>}
   */
  async amendCredit (store, { data, userId }) {
    const uri = template(apiRoutes.admin.credit.amend)({ userId })

    return userFromResponse(store, await getInstance().post(uri, data))
  },
  /**
   * Attach a role to a user.
   * @param {Store} store
   * @param {{}} payload
   * @param {string} payload.role
   * @param {number} payload.userId
   * @return {Promise<AuthUser>}
   */
  async assignRole (store, { role, userId }) {
    const uri = template(apiRoutes.admin.roles.assign)({ role, userId })

    return userFromResponse(store, await getInstance().post(uri))
  },
  /**
   * Assign a user to an MFI.
   * @param {Store} store
   * @param {number} mfiId
   * @param {number} userId
   * @return {Promise<AuthUser>}
   */
  async attachMfi (store, { mfiId, userId }) {
    const uri = template(apiRoutes.admin.mfis.attach)({ mfiId, userId })

    return userFromResponse(store, await getInstance().post(uri))
  },
  /**
   * Remove a user from an MFI.
   * @param {Store} store
   * @param {number} mfiId
   * @param {number} userId
   * @return {Promise<AuthUser>}
   */
  async detachMfi (store, { mfiId, userId }) {
    const uri = template(apiRoutes.admin.mfis.detach)({ mfiId, userId })

    return userFromResponse(store, await getInstance().post(uri))
  },
  async fetch (store, { id }) {
    store.commit('SET_USER', null)

    const uri = template(apiRoutes.admin.fetch)({ userId: id })

    return userFromResponse(store, await getInstance().get(uri))
  },
  /**
   * Fetch configuration to be used with user credit management.
   * @param {Store} store
   * @return {Promise<{}>}
   */
  async fetchCreditConfig (store) {
    const response = await getInstance().get(apiRoutes.admin.credit.config)
    store.commit('SET_CREDIT_CONFIG', get(response, 'data.data.config', null))

    return store.getters['creditConfig']
  },
  /**
   * Fetch configuration to be used for user roles management.
   * @param {Store} store
   * @return {Promise<[]>}
   */
  async fetchRolesConfig (store) {
    const response = await getInstance().get(apiRoutes.admin.roles.config)

    store.commit('SET_MANAGEABLE_ROLES', get(response, 'data.data.config.canManageRoles', null))

    return store.getters.manageableRoles
  },
  /**
   * Fetch user's transactions.
   * @param {Store} store
   * @param {AxiosRequestConfig} config - Request config
   * @param {number} id - User ID.
   * @return {Promise<void>}
   */
  async fetchTransactions (store, { config, id }) {
    const uri = template(apiRoutes.admin.transactions)({ userId: id })
    const response = await getInstance().get(uri, config)

    store.commit('SET_TRANSACTIONS', get(response, 'data.data', []))
  },
  /**
   * Mark user as deceased.
   * @param {Vuex.Store} store
   * @param {number} userId
   * @returns {Promise<AdminUser>}
   */
    async markDeceased (store, { userId }) {
      const uri = template(apiRoutes.admin.markDeceased)({ userId })

      return userFromResponse(store, await getInstance().put(uri))
    },
  /**
   * Make user account dormant.
   * @param {Vuex.Store} store
   * @param {number} userId
   * @returns {Promise<AdminUser>}
   */
  async makeDormant (store, { userId }) {
    const uri = template(apiRoutes.admin.makeDormant)({ userId })

    return userFromResponse(store, await getInstance().put(uri))
  },
  /**
   * Detach a role from a user.
   * @param {Store} store
   * @param {{}} payload
   * @param {string} payload.role
   * @param {number} payload.userId
   * @return {Promise<AuthUser>}
   */
  async revokeRole (store, { role, userId }) {
    const uri = template(apiRoutes.admin.roles.revoke)({ role, userId })

    return userFromResponse(store, await getInstance().post(uri))
  },
  /**
   * Perform API user serach.
   * @param {Store} store
   * @param {{}} payload
   * @return {Promise<{pagination: (default.getters.usersPagination|(function(*=): *)|*|null), data: (default.getters.users|*|null|default.getters.users|users|default.messages.en.stats.users)}>}
   */
  async search (store, payload) {
    const defaults = {
      limit: 15,
      page: 1,
      sort: '-id',
    }
    const params = Object.assign({}, defaults, payload)

    const response = await getInstance().get(apiRoutes.admin.index, {
      params,
    })

    store.commit('SET_USERS', get(response, 'data.data', []))
    store.commit('SET_USERS_PAGINATION', get(response, 'data.meta.pagination'), null)

    return {
      data: store.getters.users,
      pagination: store.getters.usersPagination,
    }
  },
  async updateAvatar (store, { id, publicId }) {
    const uri = template(apiRoutes.admin.update.avatar)({ userId: id })

    return userFromResponse(store, await getInstance().put(uri, { avatar: publicId }))
  },
  async updateDetails (store, { data, id }) {
    const uri = template(apiRoutes.admin.update.details)({ userId: id })

    return userFromResponse(store, await getInstance().put(uri, data))
  },
  async updateSettings (store, { data, id }) {
    const uri = template(apiRoutes.admin.update.settings)({ userId: id })

    return userFromResponse(store, await getInstance().put(uri, data))
  },
}
